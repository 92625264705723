import { Col, Row } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatPath, previewPaper } from '../../../../utils';

export default function GovernanceDetails() {
	const { projectObj } = useSelector((state) => state.obj);

	const {
		projRegNo,
		projKraPin,
		projRegType,
		projRegDate,
		projOrgPeriod,
		projStatu1File,
		projStatu2File,
		projStatu3File,
		projRegCertFile,
	} = projectObj;

	useEffect(() => {}, [projectObj]);

	return (
		<>
			<Row>
				<Col span={24}>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt_header'>Item</span>
						<span className='px-[2rem] panel_txt_header'>Details</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Type of registration</span>
						<span className='px-[2rem] panel_txt'>{projRegType}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Registration number</span>
						<span className='px-[2rem] panel_txt'>{projRegNo}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Registration Certificate
						</span>
						<button
							onClick={() => previewPaper(projRegCertFile)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projRegCertFile ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projRegCertFile)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>Date of registration</span>
						<span className='px-[2rem] panel_txt'>
							{moment(projRegDate).format('LLL')}
						</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>KRA pin</span>
						<span className='px-[2rem] panel_txt'>{projKraPin}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>
							Period the organization has been providing water services
						</span>
						<span className='px-[2rem] panel_txt'>{projOrgPeriod}</span>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>1st statutory returns</span>

                        <button
							onClick={() => previewPaper(projStatu1File)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projStatu1File ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projStatu1File)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>2nd statutory returns</span>
                        <button
							onClick={() => previewPaper(projStatu2File)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projStatu2File ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projStatu2File)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>

					</div>
					<div className='panel_card w-full grid grid-cols-2'>
						<span className='px-[2rem] panel_txt'>3rd statutory returns</span>
                        <button
							onClick={() => previewPaper(projStatu3File)}
							className='flex items-center px-[2rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='19'
								height='13'
								viewBox='0 0 19 13'
								fill='none'>
								<path
									d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
									fill='#0170BD'
								/>
							</svg>
							{projStatu3File ? (
								<span className='ml-[.44rem] table_view_txt'>
									{formatPath(projStatu3File)}
								</span>
							) : (
								<span className='ml-[.44rem] panel_txt'>No File</span>
							)}
						</button>
					</div>
				</Col>
			</Row>
		</>
	);
}
